import React from "react";

const Header = (props) => {
    return (
        <header class="header">
        <div  class="container-fluid" >
            <div class="row header-center">
                    <div class="header-left">
                        <div class="logo">
                            <a href="/">
                                <span class="img-logo"></span>
                                <span class="text-logo">Dorado Nail Bar</span>
                            </a>
                        </div>
                    </div>

                    <div class="header-right">
                        <nav class="navbar navbar-expand-lg navbar-light bg-transparent">
                            <div class="navbar-collapse">
                                <ul class="navbar-nav mr-auto no-bullets">
                                <li className="menu-item">
                                                <img src={require('../../assets/images/qr1.png')} className="qr-img" height="70" alt="" />
                                            </li>
                                            <li className="menu-item">
                                                <img src={require('../../assets/images/qr2.png')} className="qr-img" height="70" alt="" />
                                            </li>
                                            <li className="menu-item">
                                                <img src={require('../../assets/images/qr3.png')} className="qr-img" height="70" alt="" />
                                            </li>
                                </ul>
                            </div>
                        </nav>
                    </div>

            </div>
        </div>
    </header>
       
    );
}
export default Header;